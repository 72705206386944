@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Rubik+Glitch&display=swap');


*{
    font-family: "Lato", sans-serif;
}

body{
    background-color: #FAFAFA;
}

.navbar {
    top: 6.25rem;
    transform: translateY(-100%);
}


.navbar.scrolled {
    top: 0;
    transform: translateY(0%);
    transition: transform .5s ease;
}

.font-rubik{
    font-family: "Rubik Glitch", system-ui;
}


.swiper-pagination {
    bottom: 0 ;
}

.swiper-pagination-bullet{
    width: 2rem !important;
    height: .6rem !important;
    border-radius: 2rem !important;
    background-color: #7B4F28 !important;
    opacity: 50% !important;
    transition: all .3s ease-in-out;
}

.swiper-pagination-bullet-active{
    box-shadow: 0 0 10px 0 #FFC107;
    background-color: #FFC107 !important;
    opacity: 100% !important;
    width: 4.375rem !important;
}

.swiper-button-prev{
    background-color: #21A0D9 !important;
    color: white !important;
}
.swiper-button-next{
    background-color: #FFC107 !important;
    color: black !important;
}

.swiper-button-prev, .swiper-button-next{
    width: 3rem !important;
    height: 3rem !important;
    border-radius: 100%;
    font-size: 1.5rem !important;
    transition: all .2s ease-in-out;
}

.swiper-button-prev:hover{
    background-color: #48c2fb !important;
    box-shadow: 0 0 15px 0 #21A0D9;
}

.swiper-button-next:hover{
    background-color: #ffce3c !important;
    box-shadow: 0 0 15px 0 #DDBA60;
}

.swiper-button-prev::after, .swiper-button-next::after{
    content: '' !important;
}

.paragraph-container p {
    opacity: 0.5;
    font-size: .875rem;
}